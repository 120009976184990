import comparer from "fast-deep-equal";
import ImageTools from "./imagetools";
import session from "./session";
import { get, map, chain, each, isMatch } from "lodash-es";
import geo from "./geo";
const imageTools = new ImageTools();
import camera from "@parkingboss/barcam";
import haversine from "./haversine";
import { rewriteStyle, sources } from "./mapbox";

const authPrefetch = session();

async function auth() {
    var auth = await authPrefetch;
    console.log("auth", auth);
    return `${auth.type} ${auth.token}`;
}

const loadStyle = fetch(`https://api.mapbox.com/styles/v1/parkingboss/ck1ti4fzc6i9g1dnu1d5lqy1q?access_token=${mapboxgl.accessToken}`).then(res => res.json());

let ENABLED = false;

// LOCATION
(async function(root, api) {

    // this only supports a single location set...SPA-changing locations isn't supported
    // to do so we'd have to replace the simple promise with an eventing system

    //console.log("initing location");

    api.Location = api.Location || {};

	var resolveID;
    var resolvedID = new Promise(function() {
        resolveID = arguments[0];
    });

    resolveID("");

    var init = api.Location.id = function(val) {
        if(!!val) resolveID(val);
		return resolvedID;
    };

    let GEODATA = null;
    var POSITION = null;
    var MAP = new mapboxgl.Map({
        container: root.querySelector("figure.map"), // container id
        attributionControl:false,
        style: await rewriteStyle(loadStyle, GEODATA),
        center: !!POSITION ? [POSITION.coords.longitude, POSITION.coords.latitude] : [-98.5795, 39.828175],
        //maxZoom:18,
        zoom: 19, // starting zoom
        pitch:60,
        interactive:false,
        dragRotate:false,
        pitchWithRotate: false
    });
    MAP.touchZoomRotate.disableRotation();

    window.addEventListener("geofencechange", function(e) {
        console.log("updated geofence for map", e.detail);
        //geofence(e.detail);
        GEODATA = e.detail;
        sources(MAP, {
            "property":GEODATA
        });
    });

    var marker = null;
    var lastBearingPosition = null;
    var bearing = 0;
    
    function radians(n) {
        return n * (Math.PI / 180);
    }
    function degrees(n) {
        return n * (180 / Math.PI);
    }
        
    function getBearing(startLat,startLong,endLat,endLong){
        startLat = radians(startLat);
        startLong = radians(startLong);
        endLat = radians(endLat);
        endLong = radians(endLong);
        
        var dLong = endLong - startLong;
        
        var dPhi = Math.log(Math.tan(endLat/2.0+Math.PI/4.0)/Math.tan(startLat/2.0+Math.PI/4.0));
        if (Math.abs(dLong) > Math.PI){
            if (dLong > 0.0)
                dLong = -(2.0 * Math.PI - dLong);
            else
                dLong = (2.0 * Math.PI + dLong);
        }
        
        return (degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
    }

    function direction(from, to) {
        if(!from || !to) return 0;
        return getBearing(from.coords.latitude, from.coords.longitude, to.coords.latitude, to.coords.longitude);
    }

    // replace html marker with drawn image that adapts to accuracy?
    function locate(position) {
        if(!MAP) return;

        
    }

    var positions = JSON.parse(window.sessionStorage.getItem("ParkIQ-API-Positions") || "[]"); // pull from state
    // this needs to segment path based on distance/interval
    function positionsGeoJson() {
        return {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "LineString",
                "coordinates": positions.map(position => [ position.coords.longitude, position.coords.latitude ]),
            }
        };
    }

    // at most every 12 seconds
    var positionsUpdate = _.throttle(function() {
        if(positions.length > 60 * 60) positions = positions.slice(-1 * 60 * 59);
        window.sessionStorage.setItem("ParkIQ-API-Positions", JSON.stringify(positions.map(function(geoposition) {
            return {
                timestamp: geoposition.timestamp,
                coords: {
                    accuracy: geoposition.coords.accuracy,
                    altitude: geoposition.coords.altitude,
                    altitudeAccuracy: geoposition.coords.altitudeAccuracy,
                    heading: geoposition.coords.heading,
                    latitude: geoposition.coords.latitude,
                    longitude: geoposition.coords.longitude,
                    speed: geoposition.coords.speed
                }
            }
        })));
    }, 12 * 1000, {
        leading:true,
        trailing:true,
    })

    // at most every 2s
    var trackUpdate = _.throttle(function() {
        if(!!MAP) {
            var source = MAP.getSource("travel");
            if(!!source) source.setData(positionsGeoJson());
        }
    }, 2 * 1000, {
        leading:true,
        trailing:true,
    });

    function track(position) {
        positions.push(position);
        
        if(!marker) {
            var el = document.createElement("div");
            el.className = "mapboxgl-user-location-dot";

            marker = new mapboxgl.Marker(el);
        }

        // bearing - only change if moved more than accuracy?
        var dist = !lastBearingPosition ? 0 : haversine(position.coords, lastBearingPosition.coords);
        console.log("distance", dist);
        if(dist > 5) {
            bearing = direction(lastBearingPosition, position) || 0;
            console.log("new bearing", bearing);
            lastBearingPosition = position;
        }

        const center = [position.coords.longitude, position.coords.latitude];
        const radius = position.coords.accuracy;

        //item.fitBounds(center.toBounds(radius));
        marker.setLngLat(center).addTo(MAP);
        //item.panTo(center);
        MAP.easeTo({
            center:center,
            bearing:bearing
        });

        POSITION = position;
        lastBearingPosition = lastBearingPosition || POSITION;


        trackUpdate();
        positionsUpdate();
    }

    // var geofenceJson = null;
    // var geofenceUpdate = _.throttle(function() {
    //     initMapGeofence(MAP, geofenceJson);
    // }, 2 * 1000, {
    //     leading:true,
    //     trailing:true,
    // });

    
    // function geofence(json) {
    //     geofenceJson = json;
    //     geofenceUpdate();
    // }

    // function initMapGeofence(map, geofence) {

    //     if(!map || !map.getSource("travel")) return;

    //     var source = map.getSource("geofence");
    //     var area = map.getLayer("geofence-area");
    //     var outline = map.getLayer("geofence-outline");

    //     if(!geofence) {
            
    //         if(!!source) map.removeSource("geofence");
    //         if(!!area) map.removeLayer("geofence-area");
    //         if(!!outline) map.removeLayer("geofence-outline");

    //     }

    //     if(!!geofence) {

    //         if(!source) map.addSource("geofence", {
    //             type: "geojson",
    //             data: geofence,
    //         });
    //         else source.setData(geofence);

    //         if(!area) map.addLayer({
    //             "id": "geofence-area",
    //             "type": "fill",
    //             "source": "geofence",
    //             "paint": {
    //                 "fill-color": "#e57200",
    //                 "fill-opacity": 0.05
    //             },
    //         }, "travelpath");

    //         if(!outline) map.addLayer({
    //             "id": "geofence-outline",
    //             "type": "line",
    //             "source": "geofence",
    //             "paint": {
    //                 "line-color": "#e57200",
    //                 "line-width": 2,
    //                 "line-opacity":0.4
    //             },
    //         }, "travelpath");


    //     }


    // }

    function initMap(position) {

        if(!!MAP) {
            if(!!position) locate(position);
            return;
        }
        

        // const geolocate = new mapboxgl.GeolocateControl({
        //     positionOptions: {
        //         enableHighAccuracy: true
        //     },
        //     trackUserLocation: true
        // });

        // geolocate.on("geolocate", (e) => {
        //     console.log("geolocate", e);
        //     if(!!e.data) window.dispatchEvent(new CustomEvent("positionchange", {
        //         detail: e.data,
        //     }));
        // });

        // MAP.on("load", function(e) {
        //     resolve(MAP);
        //     // item.addControl(geolocate);
        //     // geolocate.trigger();
        //     // travel data
        //     MAP.addSource("travel", {
        //         type: "geojson",
        //         data: positionsGeoJson(),
        //     });

        //     // travel styling...
        //     MAP.addLayer({
        //         "id": "travelpath",
        //         "type": "line",
        //         "source": "travel",
        //         "layout": {
        //             "line-join": "round",
        //             "line-cap": "round"
        //         },
        //         "paint": {
        //             "line-color": "#1da1f2",
        //             "line-width": 1
        //         }
        //     });

        //     initMapGeofence(MAP, geofenceJson);

        // });

        POSITION = position;

    }

    //listen for position
    var locatedPosition = null;
    var currentPosition = null;
    var locationID = null;

    // maybe we should make sure this moves a certain number of meters after first time...
    function view(json) {
        var location = get(json, [ "items", map(get(json, "properties.items"))[0] ]);
        root.querySelector("body > header > h1").innerHTML = get(location, "name") || "no location";
        ENABLED = !!location;
        document.body.dispatchEvent(new CustomEvent("propertychange", {
            detail: location,
            bubbles:true,
        }));
        return json;
    }

    window.addEventListener("propertychange", e => console.log("propertychange", e.detail));

    function boundaries(json) {

        let item = json.items[map(json.properties.items)[0]];

        console.log("item", item);

        if(!item) return json;

        fetch(`https://api.parkingboss.com/v1/properties/${item.id}/map`).then(r => r.ok ? r.json() : null)
        .then(function(geofence) {
            window.dispatchEvent(new CustomEvent("geofencechange", {
                detail: geofence,
            }));
        });

        // console.log("geofence", JSON.stringify(geofence));

        

        return json;
    }

    var locate = _.throttle(function() {

        if(!!locatedPosition && !!currentPosition && haversine(locatedPosition.coords, currentPosition.coords) < 5) return; // haven't moved enough

        return Promise.all([ api.base(), dateFns.format(new Date()), geo() ])
        .then(function([ base, requested, geo ]) {
            console.log("locate geo", geo);
            locatedPosition = geo;
            return api.fetch("GET", base + "v1/locations?count=1&viewpoint=" + encodeURIComponent(requested));
        })
        .then(view)
        .then(boundaries);
    }, 30 * 1000, {
        leading:true,
        trailing:true,
    });

    //locate(); // init

    window.addEventListener("positionchange", function(e) {
        console.log("updated position", e.detail);
        currentPosition = e.detail;
        locate()
        track(e.detail);
    });

}(document.documentElement, ParkIQ.API));

// STARTUP GEO
// - wrap fetch
(function(api) {

    // TODO:
    // handle later errors?
    // recover from initial error?

    geo();

    var f = api.fetch;

    api.fetch = function(method, url, body, auth) {

        return Promise.all([url, geo()])
        .then(function([url, position]) {

            console.log("fetch coords", position.coords);

            // Object.entries(position.coords).map(([key, value]) => {
            //     if(!value) return null;
            //     console.log(key + "=" + value + "");
            //     return key + "=" + value + "";
            // }).filter(i => !!i)

            //console.log("newurl", position.coords, );

            if(!!position && !!position.coords) url = (url + (url.indexOf("?") >= 0 ? "&" : "?") + Object.entries(_.merge({}, position.coords)).filter(([key, value]) => !!value).map(([key, value]) =>  key + "=" + value + "").join("&"));

            //console.log("url", url);

            return f(method, url, body, auth);
        });

    };

}(ParkIQ.API));

// STARTUP STATE
(function(api, page, qs) {

    var state = api.State = {
        _value:JSON.parse(window.sessionStorage.getItem("ParkIQ-API-State") || "{}"), // startup from session storage
        get:function(path) {
            console.log("get", path, state._value);
            if(!path) return state._value;
            return _.get(state._value, path);
        },
        update:function(...states) {

            // normalize all the state
            _.each([ "violations.items", "permittables.items", "permits.items", "media.items", "spaces.items", "vehicles.items", "media.types.items", "tenants.items", "units.items" ], function(path) {

                _.each(states, function(json) {

                    if(!_.has(json, path)) return;
                    json.items = _.merge(json.items, _.get(json, path));

                    // remap item
                    _.each(_.get(json, path), function(value, key, collection) {
                        _.set(collection, key, key);
                    });
                
                });
                
            });

            state._value = _.merge(state._value, ...states);
            // save back to session storage
            window.sessionStorage.setItem("ParkIQ-API-State", JSON.stringify(state._value));
            console.log("state update=", states, "outcome=", state._value);

            _.defer(function() {

                window.postMessage({
                    source:"api",
                    type:"state",
                    value: state._value,
                }, "*");

            });

            return state._value;
        },
        remove:function(...paths) {

            _.each(paths, function(path) {
                console.log("state remove", path);
                _.unset(state._value, path);
            });

            // save back to session storage
            window.sessionStorage.setItem("ParkIQ-API-State", JSON.stringify(state._value));

            _.defer(function() {

                window.postMessage({
                    source:"api",
                    type:"state",
                    value: state._value,
                }, "*");

            });

        },
        equal:function(a, b) {
            return false;
            if(!a && !!b) return false;
            if(!!a && !b) return false;
            return comparer(a, b || state._value);
        }
    };


    // chain after property
    api.started = Promise.resolve(api.State.get());

    // listen for state updates...
    window.addEventListener("message", function(e) {
        if(!e || !e.data || !_.isMatch(e.data, {
            source:"api",
            type:"state",
        })) return; // doesn't match what we're looking for

        console.log("state message", e.data);
        
    });

    

}(ParkIQ.API, window.page, window.qs || window.Qs));


// STARTUP CAMERA
(function(page, root, api) {

    var figure = root.querySelector("figure.camera"); // singleton
    var video = figure.querySelector("video");
    var img = document.createElement("img");

    var cam = api.Camera = {
        root: figure,
        _capturePhoto:null,
        disable:function() {
            each(figure.querySelectorAll("button,input"), elem => {
                elem.disabled = true;
            });
        },
        enable:function() {
            each(figure.querySelectorAll("button,input"), elem => {
                elem.disabled = false;
            });
        },
        onCapture:function(frame) {

            figure.classList.add("capture");
            console.log("shutter", frame);
            if(!!frame) {
                console.log("capture frame", frame);
                Promise.resolve(frame).then(frame => {
                    console.log("update frame", frame);
                    const url = URL.createObjectURL(frame);
                    img.src = url;
                    figure.appendChild(img);
                    window.setTimeout(function() {
                        img.remove();
                        img.src = "";
                        URL.revokeObjectURL(url);
                    }, 1000);
                });
            }
            window.setTimeout(figure.classList.remove.bind(figure.classList, "capture"), 100);
        },

        capturePhoto:function() {
            return Promise.resolve(null); // default...
        }
    };

    var initPromise = null;
    function init() {
        if(!!initPromise) return initPromise.then(function(result) {
            // do anything here?
            return result;
        });

        // seetup
        return initPromise = camera(
            video || figure
        )
        .then(camera => {
            camera.root = figure;
            camera.onCapture = cam.onCapture;

            return camera;
        });
        // .catch(e => {
        //     figure.setAttribute("data-action", "manual");
        //     return {
        //         root: figure,
        //         onCapture: cam.onCapture,
        //     };
        // });
    }
    cam.get = init;

    //cam.started = init;

    
}(page, document.documentElement, ParkIQ.API));

// STARTUP SCANNER
(function(root, api, page) {

    api.localImageCache = {};

    var url = window.location.pathname + window.location.search;
    console.log("url", url);

    function observe(scope, photo, type, subject, plate, method) {

        return Promise.all([api.base(), auth(), scope, type, subject, photo])
        .then(function([base, auth, scope, type, subject, image]) {

            //console.log(base, user, auth);

            const now = new Date();
            const requested = dateFns.format(now);

            const body = new FormData();
            if(!!image) body.append("image", image, `image${Date.now()}.jpg`);
            body.append("method", method || "scanner");
            body.append(type, subject);

            return api.fetch("POST", base + "v1/observations?scope=" + scope + "&subject=" + subject + "&" + type + "=" + subject + "&plate=" + plate + "&viewpoint=" + encodeURIComponent(requested), body, auth)
            // .then(function(json) {

            //     // preload all images
            //     return Promise.all(
            //         _.chain(json)
            //         .get("items")
            //         .filter({type:"file"})
            //         .map(function(item) {
            //             if(!item || !item.url) return;
            //             return new Promise(function(resolve) {

            //                 var loader = new Image();
            //                 loader.addEventListener('load', function () {
            //                     resolve();
            //                 });
            //                 loader.src = item.url;

            //             });
            //         })
            //         .value()
            //     ).then(function() {
            //         return json;
            //     });
            // })
            .then(function(json) {
                
                // update state
                api.State.update(json);

                return json;

            });
        });

    }

    function detect(scope, photo, detectors) {

        return Promise.all([api.base(), auth(), scope, photo])
        .then(function([base, auth, scope, image]) {

            console.log(base, auth, scope, image);

            var now = new Date();
            var requested = dateFns.format(now);

            var body = new FormData();
            body.append('image', image, `image${Date.now()}.jpg`);

            let detector = detectors.shift();

            // primary detector
            return api.fetch("POST", base + "v1/observations?alpr=" + detector + "&method=scanner&scope=" + scope + "&viewpoint=" + encodeURIComponent(requested), body, auth)
            .then(function(json) {
                if(detector === "openalpr") return json;
                var item = map(get(json, "detections.items", []), item => get(json, [ "items", item, "vehicle" ])).filter(item => !!item).shift();
                if(!item && detector === "sighthound") return api.fetch("POST", base + "v1/observations?alpr=openalpr&method=scanner&scope=" + scope + "&viewpoint=" + encodeURIComponent(requested), body, auth)
                return json;
            })
            .then(function(json) {

                 // update state
                 api.State.update(json);

                 return json;

                // fire off other detectors?
                Promise.all([detectors.map(function(detector) {

                    var file = _.chain(json)
                    .get("items")
                    .filter({ type: "file" })
                    .first()
                    .get("id")
                    .value();

                    return api.fetch("POST", base + "v1/detections/vehicles?alpr=" + detector + "&scope=" + scope + "&file=" + file + "&viewpoint=" + encodeURIComponent(requested), null, auth)
                    .then(function(json) {

                         // update state
                        //api.State.update(json);


                        // var plate = _.get(json, "vehicles.detected", null);
                        // console.log("plate detection", id, plate);
                        // if(!plate) return false;
                        // vehicle(id, plate);

                        // return true;

                    });
                })]);

                return json;

            });
        });
    }

    var _inner = '<h1><% if(!!_.get(item, "record")) { %><data class="<%= _.filter([ _.get(item, "record.type"), _.get(item, "record.format") ]).join(" ") %> id" value="<%= _.get(item, "record.id") || _.get(item, "record.key") %>"><a<%= !!_.get(item, "record.url") ? " href=" + _.get(item, "record.url") : "" %>><%= _.get(item, "record.display") %></a></data><% } %></h1><section><% if(!!_.get(item, "items")) { %><ul data-records="<%= !!_.get(item, "invalid", false) || !!_.get(item, "record.invalid", false) ? "invalid" : "" %>"><% _.each(_.get(item, "items"), function(item) { %><%= _.invoke(api.Templates, _.get(item, "type"), item) %><% }); %></ul><% } %><% if(!!_.get(item, "similar")) { %><ul data-records="similar"><% _.each(_.get(item, "similar"), function(item) { %><li><%= _.invoke(api.Templates, _.get(item, "type"), item) %></li><% }); %></ul><% } %></section>';


    var complete = _.template('<li data-record-type="<%= _.get(item, "record.type") %>"><figure><img src="<%= _.get(item, "image", "") %>"><% if(_.get(item, "observable")) { %><form><input type="hidden" name="plate" value="_.get(item, "record.display")"><input type="hidden" name="vehicle" value="<%= _.get(item, "record.id") %>"><input type="file" accept="image/*"></form><% } %></figure>' + _inner + '</li>', {
        variable:"item",
        imports:{
            api:api,
        }
    });

    let lastValue = null;

    // this will return null if same as last
    function mediaid(value, skipIfLastValue) {

        if(!value || !_.startsWith(value, "http")) return null;
        
        // parse the url
        var url = _.attempt(Reflect.construct, URL, [ value ]);// not a url
 
        console.log("url", url);

        if(!url) return null;

        // decal...
        var id = url.searchParams.get("d") || _.get(url.pathname.split("/d/"), "1") || null;
        if(!skipIfLastValue) return id;

        if(id === lastValue) return null;
        lastValue = id;
        return id;
        
    }

    function ensureScrollToTop() {
        //if(!!document.body.scrollIntoView) document.body.scrollIntoView();
        window.scrollTo(0,0);
    }

    async function checkBarcode(barcode, id) {

        console.log("onBarcode", barcode);

        if(!barcode) return false;

        console.log("barcode result", "id=", id, barcode);

        return Promise.all([
            barcode.value,
            barcode.photo
        ])
        .then(function([ value, photo ]) {

            console.log("barcode.then", barcode, value, photo);

            if(!value || !mediaid(value)) return false; // there's not even an id in here, not handled

            var mediaID = mediaid(value, !id);// only skip if last value on auto-checks, id means manual tap

            if(!id) ensureScrollToTop();
            if(!id) api.Camera.onCapture(); // fake auto detect each time any barcode

            // we're only gonna cache the last one for re-read
            if(!mediaID) return true; // still consider handled


            // insert into state
            //const state = await api.State.get();
            // const photo = barcode.photo;
            // id = id || counter++;

            const localurl = URL.createObjectURL(photo);

            if(!id) {
                id = Date.now();
                const newobs = "observation-" + id;
                const newfile = "file-" + id;
                
                api.State.update({
                    items: {
                        [newobs]:{
                            created: new Date(Date.now() + 1000 * 60).toISOString(),
                            id: newobs,
                            method: "scanner",
                            media: mediaID,
                            subject: mediaID,
                            type: "observation",
                        },
                        [newfile]: {
                            type:"file",
                            id:newfile,
                            url:localurl,
                        },
                    },
                    observations: {
                        items: {
                            [newobs]:newobs,
                        }
                    },
                    attachments: {
                        items: {
                            [newobs]: {
                                [newfile]:"file",
                            },
                        },
                    },
                });
            }

            observe(api.Location.id(), photo, "media", mediaID, null, "scanner")
            .then(function(json) {

                var fileurl = _.get(_.filter(_.get(json, "items"), { type:"file"}), "[0].url");
                if(!!fileurl) _.set(api.localImageCache, fileurl, localurl);
                console.log(api.localImageCache);

                // remove placeholder detection
                api.State.remove(`items["observation-${id}"]`,`observations.items["observation-${id}"]`,`detections.items["observation-${id}"]`,`items["file-${id}"]`,`attachments.items["observation-${id}"]`);
                //api.State.remove(`items.${newobs}`,`observations.items.${newobs}`,`items.${newfile}`,`attachments.items.${newobs}`); // remove the previous one
            });


            return true;

        });


    }

    // scroll to top on record change
    new MutationObserver(function styleChangedCallback(mutations) {
        ensureScrollToTop();
    }).observe(root, {
        attributes: true,
        attributeFilter: [ "data-records", "data-record" ],
    });

    async function imageDetectVehicle(id, blob) {

        var detectors = _.orderBy(_.map(root.querySelectorAll("select[name='alpr'] option, input[name='alpr']"), function(item) {
            return {
                checked: item.checked || item.selected,
                value: item.value,
            };
        }), [ "checked" ], [ "desc", "desc" ]).map(function(item) {
            return item.value;
        });

        console.log("detectors", detectors);

        // create placeholder detection
        const photo = await blob;
        //id = id || counter++;
        const newobs = "observation-" + id;
        const newfile = "file-" + id;
        const localurl = URL.createObjectURL(photo);
        api.State.update({
            items: {
                [newobs]:{
                    created: new Date().toISOString(),
                    id: newobs,
                    method: "scanner",
                    type: "detection",
                },
                [newfile]: {
                    type:"file",
                    id:newfile,
                    url:localurl,
                },
            },
            detections: {
                items: {
                    [newobs]:newobs,
                }
            },
            attachments: {
                items: {
                    [newobs]: {
                        [newfile]:"file",
                    },
                },
            },
        });

        return detect(api.Location.id(), blob, detectors)
        .then(function(json) {

            var fileurl = _.get(_.filter(_.get(json, "items"), { type:"file"}), "[0].url");
            if(!!fileurl) _.set(api.localImageCache, fileurl, localurl);
            console.log(api.localImageCache);

            // remove placeholder detection
            api.State.remove(`items["observation-${id}"]`,`observations.items["observation-${id}"]`,`detections.items["observation-${id}"]`,`items["file-${id}"]`,`attachments.items["observation-${id}"]`);

            // var plate = _.get(json, "vehicles.detected", null);
            // console.log("plate detection", id, plate);

            // if(!plate) return false;
            // //vehicle(id, plate);

            return true;
        });


    }

    function initPhoto(id, photo) {

        // create placeholder detection
        // const photo = await blob;
        //id = id || counter++;
        const newobs = "observation-" + id;
        const newfile = "file-" + id;
        api.State.update({
            items: {
                [newobs]:{
                    created: new Date(Date.now() + 1000 * 60).toISOString(),
                    id: newobs,
                    method: "scanner",
                    type: "detection",
                },
                [newfile]: {
                    type:"file",
                    id:newfile,
                    url:URL.createObjectURL(photo),
                },
            },
            detections: {
                items: {
                    [newobs]:newobs,
                }
            },
            attachments: {
                items: {
                    [newobs]: {
                        [newfile]:"file",
                    },
                },
            },
        });
    }

    var scanRunning = false;

    window.addEventListener("propertychange", e => ENABLED = !!e.detail);

    function onCamera(camera) {
        // is camera fully inited?

        var figure = camera.root;

        if(!camera.video) {
            //figure.setAttribute("data-action", "manual");
            figure.insertAdjacentHTML("beforeend", '<input type="file" accept="image/*" capture="environment" />');
        }

        


        // update UI to yes camera mode
        const capture = document.createElement("button");
        capture.setAttribute("type", "button");
        capture.setAttribute("data-action", "capture");
        //capture.setAttribute("data-records", "vehicle media");
        figure.append(capture);

        

        let cancelBarcodeDetect = null;
        async function startPassiveQRScan() {
            if(!camera.video) return Promise.reject("Unsupported");
            return cancelBarcodeDetect = camera.detectAllBarcodes(checkBarcode);
        }
        async function stopPassiveQRScan() {
            console.log("start cancelling", cancelBarcodeDetect);
            return !!cancelBarcodeDetect ? cancelBarcodeDetect() : null;
        }

        // const observer = new IntersectionObserver(function(entries, observer) {
        //     if(root.getAttribute("data-records") !== "scan") return; // not even visible
        //     entries.forEach((entry) => {
        //         figure.style.opacity = entry.intersectionRatio;
        //         //root.classList.toggle("scrolled", !entry.isIntersecting || entry.intersectionRatio < 1);// ? "remove" : "add"]("scrolled");
        //     });
        // }, {
        //     threshold:[0, 0.5, 1]
        // });
        // observer.observe(figure);
        
        // DETECT
        // tap in detect modee
        var i = 0;
        function detect(e) {
            console.log("calling camera");

            if(figure.getAttribute("data-action") !== "detect" && figure.getAttribute("data-action") !== "manual") return; // have to be in detect mode....

            var file = e.detail;
            if(!file || !file.type || file.type.indexOf("image/") !== 0) file = null;

            console.log("file", file);
            const request = camera.detectBarcode({
                frame: file,
            });
            
            camera.onCapture(file);

            // run the detect
            var result = Promise.resolve(request)
            .then(function(request) {

                var id = request.id || `photo${i++}`;

                console.log("request.initialPhoto", id, request, request.initialPhoto);

                // wait on init photos
                Promise.resolve(request.initialPhoto)
                .then(function(photo) {
                    initPhoto(id, photo);
                    return checkBarcode(request, id);
                })
                .then(function(wasDetected) {

                    if(wasDetected) return true;

                    console.log("detecting plate", id);

                    // fetch
                    return imageDetectVehicle(id, request.initialPhoto);

                })
                .then(function() {
                    // remove placeholder detection
                    api.State.remove(`items["observation-${id}"]`,`observations.items["observation-${id}"]`,`detections.items["observation-${id}"]`,`items["file-${id}"]`,`attachments.items["observation-${id}"]`);
                    //api.State.remove(`items.${newobs}`,`observations.items.${newobs}`,`items.${newfile}`,`attachments.items.${newobs}`); // remove the previous one
                });

            });
        }
        capture.addEventListener("click", _.debounce(detect, 300, {
            leading:true,
            trailing:false,
        }));

        // image in detect mode
        figure.addEventListener("image", detect);

        var torch = document.createElement("button");
        torch.setAttribute("type", "button");
        torch.setAttribute("data-action", "torch");
        //torch.setAttribute("disabled", "disabled");
        figure.append(torch);

        torch.addEventListener("click", _.debounce(function(e) {
            
            camera.flashlight()
            .then(function(lit) {
                torch.setAttribute("value", lit + "");
            })
            .catch(function(e) {
                console.error(e);
            });

        }, 100, {
            leading:true,
            trailing:false,
        }));

        camera.hasFlashlight().then(function(hasFlashlight) {

            torch[hasFlashlight ? "removeAttribute" : "setAttribute"]("disabled", "disabled");

        });
        
        console.log("camera", camera);


        // CAPTURE
        // tap in capture mode...
        capture.addEventListener("click", _.debounce(function(e) {

            console.log("calling camera");

            if(figure.getAttribute("data-action") !== "capture") return; // have to be in detect mode....

            const request = Promise.resolve(camera.capture());

            console.log("camera", "captured");
            
            camera.onCapture();

            // run the detect
            var result = request
            .then(function(request) {

                console.log("camera", "on request result", request, "prepared to dispatch event");

                figure.dispatchEvent(new CustomEvent("capture", {
                    detail: request.initialPhoto,
                }));

                //resolve(request.initialPhoto); // resolve with promise of photo

            });


        }, 300, {
            leading:true,
            trailing:false,
        }));

        // image in capture mode
        figure.addEventListener("image", function(e) {


            if(figure.getAttribute("data-action") !== "capture") return; // have to be in capture mode...

            var file = e.detail;

            console.log("image", file);

            if(file.type.indexOf("image/") !== 0) return; // don't care about this kind

            figure.dispatchEvent(new CustomEvent("capture", {
                detail: file,
            }));
            
        });

        // generic file upload
        figure.addEventListener("change", function(e) {

            //console.log("file.onchange");

            if(!e.target.matches("input[type='file']")) return; // don't care

            var input = e.target;
            
            if(!input.files || input.files.length <= 0) return;

            _.each(input.files, function(file) {

                console.log("file", file);

                if(file.type.indexOf("image/") !== 0) return; // don't care about this kind

                // do resize here...
                
                imageTools.resize(file, {
                    width:1280,
                    height:1280,
                }, "image/jpeg", 0.45)
                .then(function(resized) {
                    console.log("resized", resized);
                    figure.dispatchEvent(new CustomEvent("image", {
                        detail: resized,
                    }));
                });

                
                
            });

            // recycle input
            input.value = null;
            input.replaceWith(input.cloneNode());

            
        });

        // camera.takePhoto = function() {

        //     figure.setAttribute("data-action", "capture");

        //     return new Promise(function(resolve) {

        //         console.log("camera", "starting promise for capture");

        //      // add a once for EITHER capture click or file input change...
        //         figure.addEventListener("capture", function(e) {

        //             console.log("camera", "on figure capture", e);
                    
        //             resolve(e.detail);
        //         }, {
        //             once:true,
        //         });

        //     });

        //     return Promise.resolve(null);

        // };

        camera.detect = function() {

            if(!ENABLED) return Promise.resolve();

            return startPassiveQRScan()
            .then(function(e) {
                figure.setAttribute("data-action", camera.video ? "detect" : "manual");
                console.log("barcode scan started", e);
            }).catch(e => {});
            //figure.setAttribute("data-action", "detect");

        };

        camera.pause = function() {
 
            return stopPassiveQRScan()
            .then(function(e) {
                figure.removeAttribute("data-action");
                console.log("barcode scan stopped", e);
            });
            figure.removeAttribute("data-action");

        };

        if(scanRunning) camera.detect(); // check for scan running in the init process...

        console.log("enabled", ENABLED);

        window.addEventListener("propertychange", e => {
            ENABLED = !!e.detail;
            console.log("propertychange, enabled=", ENABLED);
            if(!!ENABLED) camera.detect();
            else camera.pause();
        });

        return camera;
    }

    var camera = null;
    
    var cameraPromise = api.Camera.get()
    .then(onCamera)
    .then(function(inited) {
        camera = inited;
        return inited;
    })
    .catch(function(e) {

        // does this happen if can't setup?
        console.error(e);

    });

    page("*", async function(ctx, next) {
        scanRunning = true;
        root.setAttribute("data-records", "scan");
        if(!!camera) await camera.detect();
    });

    page.exit("*", async function(ctx, next) {

        scanRunning = false;
        if(!!camera) await camera.pause()

        if(!!next) next();
    });

    

}(document.documentElement, ParkIQ.API, page));


// catch all?
page("*", function(ctx) {

});

page.start();
console.log("Startup!");