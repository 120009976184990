import { each, debounce, throttle, unset, filter } from "lodash-es";
import bbox from "@turf/bbox";
import transformScale from "@turf/transform-scale";
import bboxPolygon from "@turf/bbox-polygon";

const emptySource = {
    "type":"geojson",
    "data":{
        "type": "FeatureCollection",
        "features":[],
    },
};

const urlOnLoad = new URL(window.location.href);

const layers = [
];

export async function rewriteStyle(loadStyle, loadPropertyGeoJSON) {
    var style = await loadStyle;
    
    style.sources = {
        "property":emptySource,
    };
    each(style.layers = style.layers, function (layer, i) {

        if(!!layer.source) layer.source = "property";
        unset(layer, "source-layer");

    });

    console.log(JSON.stringify(style));

    each(layers, function (layer, i) {
        style.layers.push(layer);
    });

    style.sources["property"] = {
        "type": "geojson",
        "data": (await loadPropertyGeoJSON) || emptySource.data,
    };
    
    return style;
}

export function boundsFromGeoJSON(mapUI, geojson) {

    var bounds = bbox(geojson);
    mapUI.fitBounds(bounds, {
        padding: 200,
        animate:false,
    });
    var scaled = bbox(transformScale(bboxPolygon(bounds), 3));
    console.log("scaled", scaled);
    mapUI.setMaxBounds([[ scaled[0], scaled[1] ], [ scaled[2], scaled[3] ]])
}

export function mapBBox(mapUI) {
    return mapUI.getBounds().toArray().flat();
}

export function sources(mapUI, data) {

    var ref = sources;
    var STATE = (ref.__data = ref.__data || {});

    if(!data) return;

    Object.assign(STATE, data || {});

    each(STATE, function(value, id) {
        value = value || emptySource.data;
        var source = mapUI.getSource(id);
        if(!!source) source.setData(value);
        else mapUI.addSource(id, {
            type:"geojson",
            data:value,
        });
        source = mapUI.getSource(id);
        //boundsFromGeoJSON(map, value);
    });

    //console.log("sources", STATE);

}

export function boundsFromHash(mapUI) {


    if(!!mapUI) {
        // set
        window.location.hash = `${mapUI.getZoom()}/${mapUI.getBounds().toArray().map(point => point.join(",")).join("/")}`;
        return; 
    }

    var str = urlOnLoad.hash.substr(1);
    if(!str) return;
    var parts = str.split("/");
    if(parts.length !== 2) return;
    return parts.map(point => point.split(",").map(parseFloat));
    //return [ parts[0].split(",").map(parseFloat), parseFloat(parts[1]) ];
}