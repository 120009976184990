
import store from 'store/dist/store.modern';
import jwtdecode from "jwt-decode";
const key = "Parking-Token";


async function session() {

    let auth = store.get(key);

    // var local = localStorage.getItem(key);
    // if(!!local) auth = JSON.parse(local);

    if(!!auth) {
        try {
            var decoded = jwtdecode(auth.token);
            if(!decoded || decoded.exp * 1000 < Date.now() + 60000) auth = null; // nothing or expires in the next minute or before
        } catch {
            auth = null;
        }
    }


    if(!!auth) return auth;

    auth = await fetch("https://api.parkingboss.com/v1/auth/tokens", {
        method:"POST",
    }).then(res => res.json());
    //localStorage.setItem(key, JSON.stringify(auth));
    store.set(key, auth);
    return auth;
}

export default session;