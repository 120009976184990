var last = null;
// last = { timestamp: Date.now(), coords: {
//     latitude: 47.8977822, 
//     longitude: -122.2449014,
//     accuracy: 5,
// }};

if(!!last) window.setTimeout(() => {
    window.setInterval(() => document.body.dispatchEvent(new CustomEvent("positionchange", {
        bubbles:true, detail:Object.assign(last, { timestamp: Date.now() })
    })), 1000);
}, 5000);

var initPromise = !!last ? Promise.resolve(last) : null; //null;
var cancelWatcher = null;

window.addEventListener("positionchange", function(e) {
    last = e.detail;
    if(!initPromise) initPromise = Promise.resolve(last);
    document.documentElement.classList.remove("location-error");
    document.documentElement.classList.add("location-success");
});

window.addEventListener("positionrequest", function(e) {
    document.documentElement.classList.add("location-requested");
});

window.addEventListener("positionerror", function(e) {
    document.documentElement.classList.remove("location-success");
    document.documentElement.classList.add("location-error");
});


function init() {
    if(!!initPromise) return initPromise.then(function(result) {
        // do anything here?
        return result;
    });

    // seetup
    return initPromise = new Promise(function(resolve, reject) {

        document.body.dispatchEvent(new CustomEvent("positionrequest", {
            bubbles:true,
        }));

        if (!("geolocation" in navigator)) return reject(new Error("Geolocation not supported"));

        if(!!last) return resolve(last);

        // trigger!
        navigator.geolocation.getCurrentPosition(function(position) {
            //console.log("init position", position);

            document.body.dispatchEvent(new CustomEvent("positionchange", {
                bubbles:true,
                detail:position
            }));

            // start the watcher
            cancelWatcher = navigator.geolocation.watchPosition(function(position) {
                //console.log("updated position", position);
                last = position;
                document.body.dispatchEvent(new CustomEvent("positionchange", {
                    bubbles:true,
                    detail:position
                }));
            }, function(error) {
                console.log("error");
                document.body.dispatchEvent(new CustomEvent("positionerror", {
                    bubbles:true,
                    detail:error
                }));
                // do we reject here?
            }, {
                //maximumAge: 1000, // good for one second?
                enableHighAccuracy:true,
            });


            resolve(position);


        }, reject, {
            //maximumAge: 1000, // good for one second?
            enableHighAccuracy:false,
        });

    })
    .catch(function(e) {
        document.body.dispatchEvent(new CustomEvent("positionerror", {
            bubbles:true,
            detail:e
        }));
        return Promise.reject(e);
    });
}

export default function geo() {

    return init()
    .then(function() {
        return Promise.resolve(last);
    });

};